import React from "react";
import styled from "styled-components";

export const SubmitInput = styled.input.attrs({ type: "submit" })`
    font-weight: bold;
    background-color: #50c878 !important;
    &:hover {
        background-color: #50c878;
        opacity: 0.8;
    }
    @media (prefers-color-scheme: dark) {
        background-color: teal !important;
        &:hover {
            background-color: teal;
            opacity: 0.5;
        }
    }
`;
