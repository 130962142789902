import React from "react";

const PrivacyPolicy = () => {
    return (
        <article>
            <header>
                <h1>Privacy Policy for Dinner Winner</h1>
                <p>
                    <strong>Last Updated:</strong> 8/15/2023
                </p>
            </header>

            <section>
                <h2>1. Introduction</h2>
                <p>
                    Welcome to Dinner Winner, located at{" "}
                    <a href="https://www.dinnerwinner.vote">
                        www.dinnerwinner.vote
                    </a>
                    . This privacy policy aims to inform you about the data we
                    collect, how we use it, and your rights regarding that data.
                </p>
            </section>

            <section>
                <h2>2. Data We Collect</h2>
                <ul>
                    <li>
                        <strong>Email Addresses:</strong> We collect email
                        addresses for account creation, authentication, and
                        messaging purposes.
                    </li>
                    <li>
                        <strong>Voting Data:</strong> We collect data on how
                        various meals rank in each vote to improve user
                        experience and provide meal recommendations.
                    </li>
                </ul>
            </section>

            <section>
                <h2>3. How We Use Your Data</h2>
                <ul>
                    <li>
                        <strong>Authentication:</strong> Email addresses are
                        used for account authentication.
                    </li>
                    <li>
                        <strong>Messaging:</strong> We send messages, such as
                        voting prompts, to the provided email addresses.
                    </li>
                    <li>
                        <strong>Improving User Experience:</strong> We analyze
                        voting data to enhance the platform and plan to
                        introduce meal recommendations based on past voting.
                    </li>
                </ul>
            </section>

            <section>
                <h2>4. Cookies and Tracking</h2>
                <p>
                    We use JWT (JSON Web Tokens) for authentication purposes. In
                    the future, we may implement analytics to further improve
                    the user experience. We will update this policy accordingly
                    when that happens.
                </p>
            </section>

            <section>
                <h2>5. Data Sharing and Third-Party Services</h2>
                <p>
                    We do not share your data with any third parties for
                    marketing or any other purposes. However, our website is
                    hosted on Heroku, and our data is stored on JawsDB. We are
                    committed to ensuring that these third-party services adhere
                    to strict data protection standards.
                </p>
            </section>

            <section>
                <h2>6. Data Retention</h2>
                <p>
                    Email addresses are retained for the life of the account or
                    until the user chooses to unsubscribe or close the account.
                    Voting data is retained to improve the platform and provide
                    personalized meal recommendations.
                </p>
            </section>

            <section>
                <h2>7. Your Rights</h2>
                <p>
                    You have the right to access, modify, or delete your data at
                    any time. If you wish to exercise these rights or have
                    questions about this policy, please contact us at{" "}
                    <a href="mailto:hello@dinnerwinner.vote">
                        hello@dinnerwinner.vote
                    </a>
                    .
                </p>
            </section>

            <section>
                <h2>8. Changes to This Policy</h2>
                <p>
                    We may update this privacy policy from time to time. Any
                    changes will be posted on this page, and if significant
                    changes are made, we will notify our users.
                </p>
            </section>

            <section>
                <h2>9. Data Storage Location</h2>
                <p>
                    Our website is hosted on Heroku, and our data is stored on
                    JawsDB. While the exact physical locations of these
                    services' data centers might vary, we ensure that they
                    comply with stringent data protection standards.
                </p>
            </section>
        </article>
    );
};

export default PrivacyPolicy;
