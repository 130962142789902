import React, { Fragment, useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";
import Header from "./components/Header";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import DinnerOptions from "./pages/DinnerOptions";
import Registration from "./pages/Registration";
import CreateQuickVote from "./pages/CreateQuickVote";
import QuickVoteInfo from "./pages/QuickVoteInfo";
import QuickVoteVoting from "./pages/QuickVoteVoting";
import QuickVoteResults from "./pages/QuickVoteResults";
import FAQs from "./pages/FAQs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { RunoffProvider } from "./providers/RunoffProvider";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/water.css";
import "./assets/css/main.css";

Modal.setAppElement("#root");

const App = () => {
    return (
        <Wrapper>
            <Router>
                <Header />
                <RunoffProvider>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/dinner" element={<DinnerOptions />} />
                        <Route path="/register" element={<Registration />} />
                        <Route path="/faqs" element={<FAQs />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route
                            path="/quickvote/create"
                            element={<CreateQuickVote />}
                        />
                        <Route
                            path="/quickvote/info/:uuid"
                            element={<QuickVoteInfo />}
                        />
                        <Route
                            path="/quickvote/vote/:uuid"
                            element={<QuickVoteVoting />}
                        />
                        <Route
                            path="quickvote/results/:uuid"
                            element={<QuickVoteResults />}
                        />
                        <Route
                            path="/quickvote/runoff"
                            element={<CreateQuickVote isRunoff={true} />}
                        />
                    </Routes>
                </RunoffProvider>
                {/* <WaterCssToggler /> */}
            </Router>
            <ToastContainer />
        </Wrapper>
    );
};

export default App;

const Wrapper = styled.div`
    margin-top: 60px;
`;

// Used for testing styles
// This component enables the water.css stylesheet to be toggled on and off with a query param
// which allows for comparisons with the stylecompare tool
//
// const WaterCssToggler = () => {
//     const location = useLocation();

//     useEffect(() => {
//         const params = new URLSearchParams(location.search);
//         const useWaterCss = params.get("useWaterCss");

//         const linkElement = document.getElementById("water-css");
//         if (useWaterCss === "true") {
//             if (!linkElement) {
//                 const link = document.createElement("link");
//                 link.id = "water-css";
//                 link.rel = "stylesheet";
//                 link.href = "/water.css"; // Update this to the correct path
//                 document.head.appendChild(link);
//             }
//         } else {
//             linkElement && document.head.removeChild(linkElement);
//         }
//     }, [location]);

//     return null;
// };
