import React, { Fragment, useRef, useEffect } from "react";
import styled from "styled-components";
import ClipboardJS from "clipboard";
import { toast } from "react-toastify";

interface Props {
    url: string;
    name: string;
    uuid: string | undefined;
    isShareDisabled?: boolean;
}

const ShareButton: React.FC<Props> = ({ url, name, uuid, isShareDisabled }) => {
    const clipboardRef = useRef<ClipboardJS | null>(null);

    const initializeClipboardJS = (name: string, uuid: string | undefined) => {
        const buttonElement = document.querySelector(
            `[data-clipboard-target="#id${uuid}"]`
        );

        if (buttonElement) {
            const inputElement = document.querySelector(`#id${uuid}`);
            if (inputElement) {
                clipboardRef.current = new ClipboardJS(buttonElement, {
                    target: () => inputElement,
                });
                clipboardRef.current.on("success", () => {
                    const voterNamePossessive = name ? `${name.trim()}'s` : "";
                    toast.success(
                        `Successfully copied ${voterNamePossessive} link to clipboard`
                    );
                });
            }
        }
    };

    const handleShare = (url: string) => {
        console.log(url);
        const shareData = {
            title: "Dinner Winner: Cast Your Vote!",
            text: "Care to help pick tonight's dinner? Vote now!",
            url: url,
        };
        navigator.share(shareData).catch((error) => {
            console.error("Error sharing:", error);
        });
    };
    useEffect(() => {
        // Initialize ClipboardJS for copying functionality
        initializeClipboardJS(name, uuid);

        // Cleanup on component unmount
        return () => {
            clipboardRef.current?.destroy();
        };
    }, [name, uuid]);

    if (!isShareDisabled && typeof navigator.share === "function") {
        return (
            <Button onClick={() => handleShare(url)}>Share voting link</Button>
        );
    }

    // Default to copy button
    return (
        <Fragment>
            <input id={`id${uuid}`} type="hidden" value={url} />
            <Button
                data-name={name}
                data-clipboard-target={`#id${uuid}`}
                className="copy-button"
            >
                Copy voting link to clipboard
            </Button>
        </Fragment>
    );
};

export default ShareButton;

const Button = styled.button`
    @media only screen and (max-width: 639px) {
        text-align: left;
    }
`;
