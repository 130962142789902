import React, { useState, useEffect, CSSProperties } from "react";
import styled from "styled-components";
import lightIcon from "./assets/images/draggable-burger-icon-light.png";
import darkIcon from "./assets/images/draggable-burger-icon-dark.png";
interface Props {
    size?: string;
    styles?: CSSProperties;
}
const DragIcon = ({ size = "25px", styles = {} }: Props) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        // Check the initial theme
        const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
        setIsDarkMode(darkModeQuery.matches);

        // Set up an event listener to detect theme changes
        const handleThemeChange = (e: MediaQueryListEvent) => {
            setIsDarkMode(e.matches);
        };

        darkModeQuery.addEventListener("change", handleThemeChange);

        // Clean up the event listener on component unmount
        return () => {
            darkModeQuery.removeEventListener("change", handleThemeChange);
        };
    }, []);

    const icon = isDarkMode ? darkIcon : lightIcon;

    return <IconStyled style={styles} size={size} src={icon} />;
};

interface IconProps {
    src: string;
    size: string;
}
const IconStyled = styled.div<IconProps>`
    width: ${(props: IconProps) => props.size};
    height: ${(props: IconProps) => props.size};
    opacity: 0.5;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${(props: IconProps) => props.src});
`;

export default DragIcon;
