import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getQuickVoteResults } from "../services";
import VotingResult from "../components/VotingResult";
import { useRunoffContext } from "../context";
import { toast } from "react-toastify";
import { SubmitButton } from "../components/shared/forms/buttons";

type Meal = {
    uuid: string;
    name: string;
    score: number;
};

type RankedResults = Array<Meal>;

type Deadline = Date | null;
interface RunoffOptionProps {
    voters: string[];
    winningOptions: string[];
    allOptionsTied: boolean;
}
const RunoffOption = ({
    voters,
    winningOptions,
    allOptionsTied,
}: RunoffOptionProps) => {
    const navigate = useNavigate();
    const { setRunoffData } = useRunoffContext();
    const navigateToRunoff = () => {
        navigate("/quickvote/runoff");
    };
    const handleStartRunoff = () => {
        setRunoffData({ voters, winningOptions });
        navigateToRunoff();
    };
    const message = allOptionsTied
        ? "Try adding more options"
        : "Hold a new vote with only the winning options";
    return (
        <div>
            <SubmitButton onClick={handleStartRunoff}>
                Hold a runoff vote
            </SubmitButton>
            <div>{message}</div>
        </div>
    );
};

const QuickVoteResults = () => {
    const { uuid: voterUuid } = useParams();
    const [rankedMeals, setRankedMeals] = useState([] as RankedResults);
    const [winningMeals, setWinningMeals] = useState([] as Array<string>);
    const [winningMealUuids, setWinningMealUuids] = useState(
        new Set() as Set<string>
    );
    const [isVoteComplete, setIsVoteComplete] = useState(false);
    const [hasVoted, setHasVoted] = useState([] as Array<string>);
    const [waitingFor, setWaitingFor] = useState([] as Array<string>);
    const [deadline, setDeadline] = useState(null as Deadline);
    const [isTied, setIsTied] = useState(false);
    const [allVoters, setAllVoters] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(0);

    const pollingInterval = 10000;

    const loadData = async () => {
        const { status, message, response } = await getQuickVoteResults(
            voterUuid as string,
            lastUpdated as number
        );
        if (status === 200) {
            const {
                deadline,
                voted,
                waitingFor,
                isVoteComplete,
                isTied,
                rankedMeals,
                winners,
                winningMeals,
                allVoters,
                lastUpdated: lastUpdatedTS,
            } = response;
            const winningSet = new Set<string>(winners as Array<string>);
            setIsVoteComplete(isVoteComplete);
            setHasVoted(voted);
            setWaitingFor(waitingFor);
            setDeadline(new Date(deadline));
            setRankedMeals(rankedMeals);
            setWinningMealUuids(winningSet);
            setWinningMeals(winningMeals);
            setIsTied(isTied);
            setAllVoters(allVoters);
            setLastUpdated(lastUpdatedTS);
        } else {
            toast.error(message);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(loadData, pollingInterval);
        if (isVoteComplete) {
            clearInterval(intervalId);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [lastUpdated, isVoteComplete]);

    const titleString = isVoteComplete
        ? "Final Voting Results"
        : "Preliminary Voting Results";

    const formattedDate = deadline?.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });
    const deadlineString = isVoteComplete
        ? `The Voting Deadline was ${formattedDate}`
        : `The Voting Deadline is ${formattedDate}`;
    const waitingOnString = isVoteComplete ? "Didn't vote" : "Waiting on";
    const resultsString = isVoteComplete ? "Results" : "Results (NOT FINAL)";
    const allOptionsTied = winningMeals.length === rankedMeals.length;
    const refreshMessage = isVoteComplete
        ? ""
        : "Stay on this page. The results will update as new votes come in.";
    return (
        <div>
            <h1>{titleString}</h1>
            <h3>{deadlineString}</h3>

            {hasVoted.length > 0 && (
                <Fragment>
                    <h3>Voted</h3>
                    <ul>
                        {hasVoted.map((name) => (
                            <li key={name}>{name}</li>
                        ))}
                    </ul>
                </Fragment>
            )}
            {waitingFor.length > 0 && (
                <Fragment>
                    <h3>{waitingOnString}</h3>
                    <ul>
                        {waitingFor.map((name) => (
                            <li key={name}>{name}</li>
                        ))}
                    </ul>
                </Fragment>
            )}
            <h3>{resultsString}</h3>
            <Results>
                {rankedMeals.map(({ uuid, name, score }) => {
                    const winner = winningMealUuids.has(uuid);
                    return (
                        <VotingResult
                            key={name}
                            name={name}
                            score={score}
                            winner={winner}
                        />
                    );
                })}
            </Results>
            {isTied && (
                <RunoffOption
                    voters={allVoters}
                    winningOptions={winningMeals}
                    allOptionsTied={allOptionsTied}
                />
            )}
            <p>{refreshMessage}</p>
        </div>
    );
};

const Results = styled.ul`
    padding-left: 0;
`;
export default QuickVoteResults;
