import React, { useState } from "react";
import { RunoffContext, initialRunoffData } from "../context";
import type { RunoffData } from "../context";

interface Props {
    children: React.ReactNode;
}

export const RunoffProvider: React.FC<Props> = ({ children }) => {
    const [runoffData, setRunoffData] = useState<RunoffData>(initialRunoffData);

    return (
        <RunoffContext.Provider value={{ runoffData, setRunoffData }}>
            {children}
        </RunoffContext.Provider>
    );
};
