import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import DragIcon from "./components/DragIcon";
import { SubmitInput } from "../shared/forms/inputs/submit";
import * as DND from "react-beautiful-dnd";

type Item = { id: string; name: string };
export type Items = Array<Item>;

interface Props {
    items: Items;
    droppableId: string;
    handleReorder?: (rankedItems: Items) => void;
    handleSubmit: (rankedItems: Items) => void;
}

const DragAndDropVoting = ({
    items,
    droppableId,
    handleReorder,
    handleSubmit,
}: Props) => {
    const [rankedItems, updateRankedItems] = useState(items);

    const handleDrop = (result: any) => {
        if (!result.destination) {
            return;
        }
        const itemsArray = Array.from(rankedItems);
        const [reorderedItem] = itemsArray.splice(result.source.index, 1);
        itemsArray.splice(result.destination.index, 0, reorderedItem);
        handleReorder && handleReorder(itemsArray);
        updateRankedItems(itemsArray);
    };

    const onSubmit = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(rankedItems);
    };

    return (
        <div>
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="quickvote_candidates">
                    {(provided: any) => (
                        <List
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {rankedItems.map((item, index) => (
                                <Draggable
                                    key={item.id.toString()}
                                    draggableId={item.id.toString()}
                                    index={index}
                                >
                                    {(provided: any) => (
                                        <Item
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Rank>{index + 1}</Rank>
                                            <Name>{item.name}</Name>
                                            <DragIcon
                                                size={"30px"}
                                                styles={{
                                                    position: "absolute",
                                                    right: "5px",
                                                }}
                                            />
                                        </Item>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
            <form method="post" onSubmit={onSubmit}>
                <SubmitInput type="submit" value="Submit your vote" />
            </form>
        </div>
    );
};

const List = styled.ul`
    list-style-type: none;
    padding-left: 0;
`;
const Item = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.5);

    @media (prefers-color-scheme: dark) {
        background-color: #202b38;
        border-color: rgba(255, 255, 255, 0.3);
    }
`;
const Rank = styled.span`
    margin: 0 10px;
`;
const Name = styled.span``;
export default DragAndDropVoting;
