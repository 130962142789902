import React from "react";
import styled from "styled-components";

interface Props {
    name: string;
    score: number;
    winner?: boolean;
}

interface BarProps {
    percentage: number;
    winner: boolean;
}
interface NameProps {
    winner: boolean;
}
interface ScoreProps {
    winner: boolean;
}
const VotingResult = ({ name, score, winner }: Props) => {
    return (
        <ListItem>
            <Name winner={winner}>{name}</Name>
            <Score winner={winner}>{score}%</Score>
            <Bar winner={winner} percentage={score} />
        </ListItem>
    );
};

const ListItem = styled.li`
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: none;
    &:last-child {
        border: 1px solid rgba(0, 0, 0, 0.3);
    }
    @media (prefers-color-scheme: dark) {
        border-color: rgba(255, 255, 255, 0.3);
        &:last-child {
            border-color: rgba(255, 255, 255, 0.3);
        }
    }
`;

const Name = styled.div<NameProps>`
    margin-right: 10px;
    z-index: 10;
    font-weight: ${(props: NameProps) => (props.winner ? "bold" : "normal")};
`;
const Score = styled.div<ScoreProps>`
    font-weight: ${(props: ScoreProps) => (props.winner ? "bold" : "normal")};
`;

const Bar = styled.div<BarProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props: BarProps) => props.percentage}%;
    height: 100%;
    background-color: ${(props: BarProps) =>
        props.winner ? "#50c878" : "rgba(0, 0, 0, 0.1)"};
    @media (prefers-color-scheme: dark) {
        background-color: ${(props: BarProps) =>
            props.winner ? "teal" : "rgba(255, 255, 255, 0.1);"};
    }
`;

export default VotingResult;
