import React from "react";
import styled from "styled-components";

const FAQs = () => {
    return (
        <div>
            <h2>FAQs</h2>
            <h3>What is Dinner Winner?</h3>
            <p>
                Dinner Winner helps households decide on meals, be it dinner or
                any other time of the day. Currently Dinner Winner supports
                quick voting, which is useful if you're having trouble reaching
                a consensus on what to prepare or which restaurant to go to.
                Planned features will allow households to save meals and
                restaurants, vote on weekly menus, and receive intelligent
                suggestions based on voting history.
            </p>
            <h3>Why does Dinner Winner exist?</h3>
            <p>
                The primary reason that Dinner Winner exists is because my
                family would often spend an entire hour coming to an agreement
                on what to have for dinner. I became tired of this tedious,
                unnecessary process, and decided to build a solution.
            </p>
            <p>
                My secondary motivation in building Dinner Winner was to learn
                more about artificial intelligence. I've dabbled in some machine
                learning in the past, but never with neural networks. Dinner
                Winner seemed like the perfect project for gaining experience
                with neural networks.
            </p>
            <h3>
                There are many voting applications out there, why build another?
            </h3>
            <p>
                While voting is built into many social network applications,
                those implementations are not always ideal for households. They
                generally require that everyone participating in the vote have
                an account on that platform. That's fine when you're deciding on
                where to have lunch with colleages using your company's
                messaging app, but problematic for households where children may
                be too young for a social media account, or where a member of
                the household has opted out of joining one.
            </p>
            <p>
                By focusing on one specific problem, I'm also able to offer a
                more tailored solution. Since most households tend to eat the
                same or similar foods over time, there is value in being able to
                save a list of meals that you like, and even be reminded about
                favorite dishes that you may have forgotten about.
            </p>
            <h3>How is Dinner Winner monetized?</h3>
            <p>
                Dinner Winner is not monetized. This is entirely a personal
                project.
            </p>
        </div>
    );
};

export default FAQs;
