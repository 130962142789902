import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getQueryParameter } from "./utils";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

// toggling strict mode because it's incompatible with react-beautiful-dnd
const useStrictMode = getQueryParameter("strict") !== "false";

root.render(
    useStrictMode ? (
        <React.StrictMode>
            <App />
        </React.StrictMode>
    ) : (
        <App />
    )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
