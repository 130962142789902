import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getQuickVoteVotingPageDetails, NetworkResponse } from "../services";
import DragAndDropVoting from "../components/DragAndDropVoting";
import { submitQuickVoteService } from "../services";
import type { Items } from "../components/DragAndDropVoting";

const QuickVoteVoting = () => {
    const { uuid: voterUuid } = useParams();

    const [voterName, setVoterName] = useState("");
    const [candidates, setCandidates] = useState([]);
    const navigate = useNavigate();

    const navigateToResults = () => {
        navigate(`/quickvote/results/${voterUuid}`);
    };

    const navigateTo = (url: string) => {
        navigate(url);
    };

    const loadData = async (voterUuid: string) => {
        const { status, message, response } =
            await getQuickVoteVotingPageDetails(voterUuid);

        if (status === 200) {
            const { voter_name, candidates } = response;
            setVoterName(voter_name);
            setCandidates(
                candidates.map(
                    ({
                        meal_uuid,
                        meal_name,
                    }: {
                        meal_uuid: string;
                        meal_name: string;
                    }) => {
                        return { id: meal_uuid, name: meal_name };
                    }
                )
            );
        } else if (status === 299) {
            const { redirectTo } = response;
            navigateTo(redirectTo);
        } else {
            toast.error(message);
        }
    };

    const handleSubmit = async (rankedItems: Items) => {
        const rankedIds = rankedItems.map((item) => item.id);
        if (voterUuid) {
            const { status, message, response } = await submitQuickVoteService(
                voterUuid,
                rankedIds
            );

            if (status === 200) {
                toast.success("Your vote has been recorded");
                navigateToResults();
            } else if (status === 403) {
                toast.warning(response.message);
                navigateToResults();
            } else {
                toast.error(response.message);
            }
        }
    };

    useEffect(() => {
        loadData(voterUuid as string);
    }, []);

    return (
        <div>
            {voterName && <h1>{voterName}'s Ballot</h1>}
            <p>
                Drag and drop options to rank your choices. Place your favorites
                at the top, and least favorites at the bottom.
            </p>
            {candidates.length && (
                <DragAndDropVoting
                    items={candidates}
                    droppableId="quickvote_candidates"
                    handleSubmit={handleSubmit}
                />
            )}
        </div>
    );
};

export default QuickVoteVoting;
